.phone-input .flag {
  width: 25px;
  height: 25px;
  background-image: url('../../public/images/country-flags/all.png');
  background-repeat: no-repeat;
}
.phone-input .ad {
  background-position: -48px -24px;
}
.phone-input .ae {
  background-position: -72px -24px;
}
.phone-input .af {
  background-position: -96px -24px;
}
.phone-input .ag {
  background-position: -120px -24px;
}
.phone-input .ai {
  background-position: -144px -24px;
}
.phone-input .al {
  background-position: -168px -24px;
}
.phone-input .am {
  background-position: -192px -24px;
}
.phone-input .an {
  background-position: -216px -24px;
}
.phone-input .ao {
  background-position: -240px -24px;
}
.phone-input .aq {
  background-position: -264px -24px;
}
.phone-input .ar {
  background-position: -288px -24px;
}
.phone-input .as {
  background-position: -312px -24px;
}
.phone-input .at {
  background-position: -336px -24px;
}
.phone-input .au {
  background-position: -360px -24px;
}
.phone-input .aw {
  background-position: -384px -24px;
}
.phone-input .ax {
  background-position: 0px -48px;
}
.phone-input .az {
  background-position: -24px -48px;
}
.phone-input .ba {
  background-position: -48px -48px;
}
.phone-input .bb {
  background-position: -72px -48px;
}
.phone-input .bd {
  background-position: -96px -48px;
}
.phone-input .be {
  background-position: -120px -48px;
}
.phone-input .bf {
  background-position: -144px -48px;
}
.phone-input .bg {
  background-position: -168px -48px;
}
.phone-input .bh {
  background-position: -192px -48px;
}
.phone-input .bi {
  background-position: -216px -48px;
}
.phone-input .bj {
  background-position: -240px -48px;
}
.phone-input .bl {
  background-position: -264px -48px;
}
.phone-input .bm {
  background-position: -288px -48px;
}
.phone-input .bn {
  background-position: -312px -48px;
}
.phone-input .bo {
  background-position: -336px -48px;
}
.phone-input .br {
  background-position: -360px -48px;
}
.phone-input .bs {
  background-position: -384px -48px;
}
.phone-input .bt {
  background-position: 0px -72px;
}
.phone-input .bw {
  background-position: -24px -72px;
}
.phone-input .by {
  background-position: -48px -72px;
}
.phone-input .bz {
  background-position: -72px -72px;
}
.phone-input .ca {
  background-position: -96px -72px;
}
.phone-input .cc {
  background-position: -120px -72px;
}
.phone-input .cd {
  background-position: -144px -72px;
}
.phone-input .cf {
  background-position: -168px -72px;
}
.phone-input .cg {
  background-position: -192px -72px;
}
.phone-input .ch {
  background-position: -216px -72px;
}
.phone-input .ci {
  background-position: -240px -72px;
}
.phone-input .ck {
  background-position: -264px -72px;
}
.phone-input .cl {
  background-position: -288px -72px;
}
.phone-input .cm {
  background-position: -312px -72px;
}
.phone-input .cn {
  background-position: -336px -72px;
}
.phone-input .co {
  background-position: -360px -72px;
}
.phone-input .cr {
  background-position: -384px -72px;
}
.phone-input .cu {
  background-position: 0px -96px;
}
.phone-input .cv {
  background-position: -24px -96px;
}
.phone-input .cw {
  background-position: -48px -96px;
}
.phone-input .cx {
  background-position: -72px -96px;
}
.phone-input .cy {
  background-position: -96px -96px;
}
.phone-input .cz {
  background-position: -120px -96px;
}
.phone-input .de {
  background-position: -144px -96px;
}
.phone-input .dj {
  background-position: -168px -96px;
}
.phone-input .dk {
  background-position: -192px -96px;
}
.phone-input .dm {
  background-position: -216px -96px;
}
.phone-input .do {
  background-position: -240px -96px;
}
.phone-input .dz {
  background-position: -264px -96px;
}
.phone-input .ec {
  background-position: -288px -96px;
}
.phone-input .ee {
  background-position: -312px -96px;
}
.phone-input .eg {
  background-position: -336px -96px;
}
.phone-input .eh {
  background-position: -360px -96px;
}
.phone-input .er {
  background-position: -384px -96px;
}
.phone-input .es {
  background-position: 0px -120px;
}
.phone-input .et {
  background-position: -24px -120px;
}
.phone-input .eu {
  background-position: -48px -120px;
}
.phone-input .fi {
  background-position: -72px -120px;
}
.phone-input .fj {
  background-position: -96px -120px;
}
.phone-input .fk {
  background-position: -120px -120px;
}
.phone-input .fm {
  background-position: -144px -120px;
}
.phone-input .fo {
  background-position: -168px -120px;
}
.phone-input .fr {
  background-position: -192px -120px;
}
.phone-input .ga {
  background-position: -216px -120px;
}
.phone-input .gb {
  background-position: -240px -120px;
}
.phone-input .gd {
  background-position: -264px -120px;
}
.phone-input .ge {
  background-position: -288px -120px;
}
.phone-input .gg {
  background-position: -312px -120px;
}
.phone-input .gh {
  background-position: -336px -120px;
}
.phone-input .gi {
  background-position: -360px -120px;
}
.phone-input .gl {
  background-position: -384px -120px;
}
.phone-input .gm {
  background-position: 0px -144px;
}
.phone-input .gn {
  background-position: -24px -144px;
}
.phone-input .gq {
  background-position: -48px -144px;
}
.phone-input .gr {
  background-position: -72px -144px;
}
.phone-input .gs {
  background-position: -96px -144px;
}
.phone-input .gt {
  background-position: -120px -144px;
}
.phone-input .gu {
  background-position: -144px -144px;
}
.phone-input .gw {
  background-position: -168px -144px;
}
.phone-input .gy {
  background-position: -192px -144px;
}
.phone-input .hk {
  background-position: -216px -144px;
}
.phone-input .hn {
  background-position: -240px -144px;
}
.phone-input .hr {
  background-position: -264px -144px;
}
.phone-input .ht {
  background-position: -288px -144px;
}
.phone-input .hu {
  background-position: -312px -144px;
}
.phone-input .ic {
  background-position: -336px -144px;
}
.phone-input .id {
  background-position: -360px -144px;
}
.phone-input .ie {
  background-position: -384px -144px;
}
.phone-input .il {
  background-position: 0px -168px;
}
.phone-input .im {
  background-position: -24px -168px;
}
.phone-input .in {
  background-position: -48px -168px;
}
.phone-input .iq {
  background-position: -72px -168px;
}
.phone-input .ir {
  background-position: -96px -168px;
}
.phone-input .is {
  background-position: -120px -168px;
}
.phone-input .it {
  background-position: -144px -168px;
}
.phone-input .je {
  background-position: -168px -168px;
}
.phone-input .jm {
  background-position: -192px -168px;
}
.phone-input .jo {
  background-position: -216px -168px;
}
.phone-input .jp {
  background-position: -240px -168px;
}
.phone-input .ke {
  background-position: -264px -168px;
}
.phone-input .kg {
  background-position: -288px -168px;
}
.phone-input .kh {
  background-position: -312px -168px;
}
.phone-input .ki {
  background-position: -336px -168px;
}
.phone-input .xk {
  background-position: -144px 0px;
}
.phone-input .km {
  background-position: -360px -168px;
}
.phone-input .kn {
  background-position: -384px -168px;
}
.phone-input .kp {
  background-position: 0px -192px;
}
.phone-input .kr {
  background-position: -24px -192px;
}
.phone-input .kw {
  background-position: -48px -192px;
}
.phone-input .ky {
  background-position: -72px -192px;
}
.phone-input .kz {
  background-position: -96px -192px;
}
.phone-input .la {
  background-position: -120px -192px;
}
.phone-input .lb {
  background-position: -144px -192px;
}
.phone-input .lc {
  background-position: -168px -192px;
}
.phone-input .li {
  background-position: -192px -192px;
}
.phone-input .lk {
  background-position: -216px -192px;
}
.phone-input .lr {
  background-position: -240px -192px;
}
.phone-input .ls {
  background-position: -264px -192px;
}
.phone-input .lt {
  background-position: -288px -192px;
}
.phone-input .lu {
  background-position: -312px -192px;
}
.phone-input .lv {
  background-position: -336px -192px;
}
.phone-input .ly {
  background-position: -360px -192px;
}
.phone-input .ma {
  background-position: -384px -192px;
}
.phone-input .mc {
  background-position: 0px -216px;
}
.phone-input .md {
  background-position: -24px -216px;
}
.phone-input .me {
  background-position: -48px -216px;
}
.phone-input .mf {
  background-position: -72px -216px;
}
.phone-input .mg {
  background-position: -96px -216px;
}
.phone-input .mh {
  background-position: -120px -216px;
}
.phone-input .mk {
  background-position: -144px -216px;
}
.phone-input .ml {
  background-position: -168px -216px;
}
.phone-input .mm {
  background-position: -192px -216px;
}
.phone-input .mn {
  background-position: -216px -216px;
}
.phone-input .mo {
  background-position: -240px -216px;
}
.phone-input .mp {
  background-position: -264px -216px;
}
.phone-input .mq {
  background-position: -288px -216px;
}
.phone-input .mr {
  background-position: -312px -216px;
}
.phone-input .ms {
  background-position: -336px -216px;
}
.phone-input .mt {
  background-position: -360px -216px;
}
.phone-input .mu {
  background-position: -384px -216px;
}
.phone-input .mv {
  background-position: 0px -240px;
}
.phone-input .mw {
  background-position: -24px -240px;
}
.phone-input .mx {
  background-position: -48px -240px;
}
.phone-input .my {
  background-position: -72px -240px;
}
.phone-input .mz {
  background-position: -96px -240px;
}
.phone-input .na {
  background-position: -120px -240px;
}
.phone-input .nc {
  background-position: -144px -240px;
}
.phone-input .ne {
  background-position: -168px -240px;
}
.phone-input .nf {
  background-position: -192px -240px;
}
.phone-input .ng {
  background-position: -216px -240px;
}
.phone-input .ni {
  background-position: -240px -240px;
}
.phone-input .nl {
  background-position: -264px -240px;
}
.phone-input .no {
  background-position: -288px -240px;
}
.phone-input .np {
  background-position: -312px -240px;
}
.phone-input .nr {
  background-position: -336px -240px;
}
.phone-input .nu {
  background-position: -360px -240px;
}
.phone-input .nz {
  background-position: -384px -240px;
}
.phone-input .om {
  background-position: 0px -264px;
}
.phone-input .pa {
  background-position: -24px -264px;
}
.phone-input .pe {
  background-position: -48px -264px;
}
.phone-input .pf {
  background-position: -72px -264px;
}
.phone-input .pg {
  background-position: -96px -264px;
}
.phone-input .ph {
  background-position: -120px -264px;
}
.phone-input .pk {
  background-position: -192px -264px;
}
.phone-input .pl {
  background-position: -216px -264px;
}
.phone-input .pn {
  background-position: -240px -264px;
}
.phone-input .pr {
  background-position: -264px -264px;
}
.phone-input .ps {
  background-position: -288px -264px;
}
.phone-input .pt {
  background-position: -312px -264px;
}
.phone-input .pw {
  background-position: -336px -264px;
}
.phone-input .py {
  background-position: -360px -264px;
}
.phone-input .qa {
  background-position: -384px -264px;
}
.phone-input .ro {
  background-position: 0px -288px;
}
.phone-input .rs {
  background-position: -24px -288px;
}
.phone-input .ru {
  background-position: -48px -288px;
}
.phone-input .rw {
  background-position: -72px -288px;
}
.phone-input .sa {
  background-position: -96px -288px;
}
.phone-input .sb {
  background-position: -120px -288px;
}
.phone-input .sc {
  background-position: -144px -288px;
}
.phone-input .sd {
  background-position: -168px -288px;
}
.phone-input .se {
  background-position: -192px -288px;
}
.phone-input .sg {
  background-position: -216px -288px;
}
.phone-input .sh {
  background-position: -240px -288px;
}
.phone-input .si {
  background-position: -264px -288px;
}
.phone-input .sk {
  background-position: -288px -288px;
}
.phone-input .sl {
  background-position: -312px -288px;
}
.phone-input .sm {
  background-position: -336px -288px;
}
.phone-input .sn {
  background-position: -360px -288px;
}
.phone-input .so {
  background-position: -384px -288px;
}
.phone-input .sr {
  background-position: 0px -312px;
}
.phone-input .ss {
  background-position: -24px -312px;
}
.phone-input .st {
  background-position: -48px -312px;
}
.phone-input .sv {
  background-position: -72px -312px;
}
.phone-input .sy {
  background-position: -96px -312px;
}
.phone-input .sz {
  background-position: -120px -312px;
}
.phone-input .tc {
  background-position: -144px -312px;
}
.phone-input .td {
  background-position: -168px -312px;
}
.phone-input .tf {
  background-position: -192px -312px;
}
.phone-input .tg {
  background-position: -216px -312px;
}
.phone-input .th {
  background-position: -240px -312px;
}
.phone-input .tj {
  background-position: -264px -312px;
}
.phone-input .tk {
  background-position: -288px -312px;
}
.phone-input .tl {
  background-position: -312px -312px;
}
.phone-input .tm {
  background-position: -336px -312px;
}
.phone-input .tn {
  background-position: -360px -312px;
}
.phone-input .to {
  background-position: -384px -312px;
}
.phone-input .tr {
  background-position: 0px -336px;
}
.phone-input .tt {
  background-position: -24px -336px;
}
.phone-input .tv {
  background-position: -48px -336px;
}
.phone-input .tw {
  background-position: -72px -336px;
}
.phone-input .tz {
  background-position: -96px -336px;
}
.phone-input .ua {
  background-position: -120px -336px;
}
.phone-input .ug {
  background-position: -144px -336px;
}
.phone-input .us {
  background-position: -168px -336px;
}
.phone-input .uy {
  background-position: -192px -336px;
}
.phone-input .uz {
  background-position: -216px -336px;
}
.phone-input .va {
  background-position: -240px -336px;
}
.phone-input .vc {
  background-position: -264px -336px;
}
.phone-input .ve {
  background-position: -288px -336px;
}
.phone-input .vg {
  background-position: -312px -336px;
}
.phone-input .vi {
  background-position: -336px -336px;
}
.phone-input .vn {
  background-position: -360px -336px;
}
.phone-input .vu {
  background-position: -384px -336px;
}
.phone-input .wf {
  background-position: 0px -360px;
}
.phone-input .ws {
  background-position: -24px -360px;
}
.phone-input .ye {
  background-position: -48px -360px;
}
.phone-input .za {
  background-position: -96px -360px;
}
.phone-input .zm {
  background-position: -120px -360px;
}
.phone-input .zw {
  background-position: -144px -360px;
}
.phone-input * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
