#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: rgb(221, 34, 34);

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 4px;
}

#nprogress .scan {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 20px auto !important;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.363);
}

#nprogress .scan .svg {
  display: block;
  position: relative;
  width: 150px;
}

#nprogress .scan .svg .st0 {
  clip-path: url(#SVGID_2_);
}
#nprogress .scan .svg .st0.st1x {
  fill: #e6e6e6;
}
#nprogress .scan .svg .st0 .st1 {
  clip-path: url(#SVGID_4_);
  fill: #cf202b;
  -webkit-animation: scan 1.5s infinite;
  animation: scan 1.5s infinite;
  -moz-animation: scan 1.5s infinite;
  -ms-animation: scan 1.5s infinite;
  -webkit-animation-direction: alternate-reverse;
  animation-direction: alternate-reverse;
  -ms-animation-direction: alternate-reverse;
  -moz-animation-direction: alternate-reverse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -ms-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #29d,
    0 0 5px #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes nprogress-scan {
  0% {
    -webkit-transform: translate(230px, 0);
    transform: translate(230px, 0);
    -ms-transform: translate(230px, 0);
  }
  100% {
    -webkit-transform: translate(-236.7px, 0);
    transform: translate(-236.7px, 0);
    -ms-transform: translate(-236.7px, 0);
  }
}
@keyframes nprogress-scan {
  0% {
    -webkit-transform: translate(230px, 0);
    transform: translate(230px, 0);
    -ms-transform: translate(230px, 0);
  }
  100% {
    -webkit-transform: translate(-236.7px, 0);
    transform: translate(-236.7px, 0);
    -ms-transform: translate(-236.7px, 0);
  }
}
