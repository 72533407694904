@tailwind base;
@tailwind components;
@tailwind utilities;

/*font-thin	=> font-weight: 100;*/
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/gotham/Gotham-Thin.otf') format('opentype');
}

/*font-extralight	=> font-weight: 200;*/
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/gotham/Gotham-XLight.otf') format('opentype');
}

/*font-light	=> font-weight: 300;*/
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/gotham/Gotham-Light.otf') format('opentype');
}

/*font-normal	=> font-weight: 400;*/
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/gotham/Gotham-Light.otf') format('opentype');
}

/*font-medium	=> font-weight: 500;*/
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/gotham/Gotham-Medium.otf') format('opentype');
}

/*font-semibold	=> font-weight: 600;*/
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/gotham/Gotham-Medium.otf') format('opentype');
}

/*font-bold	=> font-weight: 700;*/
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/gotham/Gotham-Bold.otf') format('opentype');
}

/*font-extrabold	=> font-weight: 800;*/
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/gotham/Gotham-Ultra.otf') format('opentype');
}

/*font-black	=> font-weight: 900;*/
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/gotham/Gotham-Black.otf') format('opentype');
}

:root {
  --headlineFont: 'Nexa Text', 'Noto Sans', 'Gotham', sans-serif;
  --font: 'Nexa Text', 'Noto Sans', 'Gotham', sans-serif;
  --text: #2a303c;
  --textInverse: #fafafa;
  --textLight: #686262;
  --textLink: #0076de;
  --textBlue: #086ce8;
  --textSecondary: #63718d;
  --primarySolid: #fff;

  --primary: #fcfcfd;
  --primaryPanel: #f6f7f9;
  --primaryPanel2: #f4f6f8;
  --primaryAccent: #055398;
  --primaryBase: #086ae3;

  --primaryText: #333333;
  --secondaryAccent: #ce392b;
  --secondaryPanel: #eff7fe;
  --dividePrimary: #eeeeee;
  --borderPrimary: #781e74;
  --borderSecondary: #d0b0cf;
  --labCardIcon: #781e74;
  --primarySidenav: #f7eee0;
  --primaryHover: #d9e8ff;
  --primaryHeader: #fdf8f2;
  --greyButton: #888888;
  --greyTxtButton: #2a303c;
  --navLink: #686262;
  --navLinkActive: #055398;
  --greyBorder: #e7e9ee;
  --inputBg: #eee;
  --tableHeader: #f4f6f8;
  --statusActive: #00a85f;
  --transparentPanel: #33333380;
  --danger: #c95252;
  --warn: #f2bd74;
  --warning: #fef7f0;
  --caution: #c9c152;
  --success: #5cc952;
  --notify: #52b7c9;
  --offwhite: var(--textLight);
  --grey: #dedede;
  --disabled: #696969;
  --shadow: rgba(0, 0, 0, 0.3);
  --olayDark: rgba(161, 161, 161, 0.5);
  --olayLight: rgba(255, 255, 255, 0.6);
  --ulayBlueish: #f0f7fe;
  --scrollOffset: 84px;
  --toastInfo: #eff7fe;
  --toastError: #fff0f1;
  --toastWarning: #fff8ee;
  --toastSuccess: #f0fff9;
  --toastInfoText: #055398;
  --toastErrorText: #d10810;
  --toastWarningText: #e8831c;
  --toastSuccessText: #00a85f;
  --toastWarningDarkText: #844605;
  --toast2Info: #0d6cac;
  --toast2Error: #d7212d;
  --toast2Warning: #dd7409;
  --toast2Success: #00854b;
  /* Lab 2.0 colors */
  --primaryLab: #fcfcfd;
  --primaryPanelLab: #f7eee0;
  --primaryPanel2Lab: #f4f6f8;
  --textPrimary: #2a303c;
  --disableBtn: #96a1b5;
  --progressBar: #086ce8;
  --avatarBg: #cfe6fc;
  --checkIconBg: #b5d5fc;
  --textAreaBorder: rgba(42, 48, 60, 0.2);
  --crossIcon: rgba(42, 48, 60, 0.35);
  --sidePanelItemHoverBg: rgba(255, 255, 255, 0.35);
  --sidePanelDropdownBg: rgba(42, 48, 60, 0.1);
  --greenProgressBar: #00854b;
  --btnDanger: #d53811;
  --bellIcon: #f68c1f;
  --errorBg: #fef3f1;
  --errorBorder: #fcded9;
  --avatarBorder: #00a85f;
  --bgSecondary: #f0f7fe;
  --white: #ffffff;
  --bg3: #f6f7f9;
  --greyIcon: #2a303c59;
  --border4: #e7e9ee;
  --activeMenuItem: rgba(255, 255, 255, 0.35);
  --activeMainMenuItem: #e7f3ff;
  --activeMainMenuItem2: rgba(8, 108, 232, 0.1);
  --elementHighlight: #f0f7fe;
  --osChipActive: #0653b2;
  --breadcrumbGrey: #c3c9d5;
  --statusActiveBg: #f2fdf8;
  --statusInactive: #a52c0d;
  --osChipInfoBorder: #2b87f8;
  --labSecondary: #94784e;
  --serverEvent: #de0873;
  --billingEvent: #8308e8;
  --notificationEvent: #ffc41a;
  --userEvent: #03b1ba;
  --storageEvent: #d407c3;
  --networkevent: #53b7e8;
  /*TEXT COLOR*/
  --textDisabled: #96a1b5;
  /*BUTTON COLOR*/
  --buttonBlue: #086ce8;
  --buttonBlueHover: #2b87f8;
  --buttonBlueActive: #0653b2;
  --buttonBlueBorder: #b5d5fc;
  --buttonBlueBorderlessHover: #086ae3;
  --buttonGreen: #008087;
  --buttonGreenHover: #4ca6ab;
  --buttonGreenActive: #004c51;
  --buttonGreenBorder: #b5d5fc;
  --buttonWhite: #ffffff;
  --buttonWhiteHover: #2b87f8;
  --buttonWhiteActive: #f0f7fe;
  --buttonDanger: #d53811;
  --buttonDangerHover: #f06542;
  --buttonDangerHoverBg: #fef3f1;
  --buttonDangerActive: #a52c0d;
  /*BADGE COLOR*/
  --badgeGrey: #e7e9ee;
  --badgeYellow: #f7eee0;
  /*SHADOW COLOR*/
  --shadowLight: rgba(42, 48, 60, 0.1);
  --grayStatusDot: #546078;
  --redAsterisk: #d32f2f;
  --textYellow: #b56007;
  /*BORDER COLOR*/
  --borderYellow: #fabe80;
  --borderBlueish: #b5d5fc;

  /* Lab colors */
  --primaryBgLab: #2b3b4c;
  --secondaryBgLab: #3f485a;
  --accentBgLab: #1f2735;
  --highlightLab: #00d4df;
  --highlightBgLab: #e6f8f9;
  --highlghtAccessible: #008087;
  --primaryTextLab: #ffffff;
  --disabledTextLab: #96a1b5;
  --baseAccentLab: #00d4df;
  --borderBlue: #2b87f8;
  --primaryActive: #0653b2;
  --blue900: #021c3b;
  --translucentDark: #2a303c33;

  --green50: #f2fdf8;
  --green100: #baf7dd;
  --disabledFill: #f5f5f5;
  --blue50: #f0f7fe;
  --blue100: #cfe6fc;
  --translucentBlue: #086ce81a;
  --disabledPrimary: #707073;
}

:root .dark {
  --headlineFont: 'Nexa Text', 'Noto Sans', 'Gotham', sans-serif;
  --font: 'Nexa Text', 'Noto Sans', 'Gotham', sans-serif;
  --text: #e5e3df;
  --textInverse: #131313;
  --textLight: #fafafa;
  --textLink: #8e86dc;
  --textBlue: #086ce8;
  --textSecondary: #63718d;
  --primarySolid: #101010;
  --primary: #1b1d1e;
  --primaryPanel: #131516;
  --primaryPanel2: #2d2d2d;
  --primaryAccent: #319eff;
  --primaryText: #333333;
  --primaryHover: #7a06ce;
  --primaryBase: #086ae3;
  --secondaryAccent: #055398;
  --secondaryHover: #ce392b;
  --secondaryPanel: #131516;
  --dividePrimary: #fbfbfb;
  --borderPrimary: #0076de;
  --borderSecondary: #0076de;
  --labCardIcon: #e5e3df;
  --navLink: #fafafa;
  --navLinkActive: #055398;
  --greyBorder: #1f1f1f;
  --inputBg: #eee;
  --tableHeader: #000;
  --statusActive: #00a85f;
  --transparentPanel: #33333350;
  --danger: #c95252;
  --warn: #f2bd74;
  --warning: #fef7f0;
  --caution: #c9c152;
  --success: #5cc952;
  --notify: #52b7c9;
  --offwhite: var(--textLight);
  --grey: #dedede;
  --disabled: #696969;
  --shadow: rgba(0, 0, 0, 0.3);
  --olayDark: rgba(0, 0, 0, 0.75);
  --olayLight: rgba(0, 0, 0, 0.6);
  --ulayBlueish: #2c4c74;

  --scrollOffset: 84px;
  --toastInfo: #eff7fe;
  --toastError: #fff0f1;
  --toastWarning: #fff8ee;
  --toastSuccess: #f0fff9;
  --toastInfoText: #055398;
  --toastErrorText: #d10810;
  --toastWarningText: #e8831c;
  --toastSuccessText: #00a85f;
  --toastWarningDarkText: #844605;
  --toast2Info: #086ce8;
  --toast2Error: #d53811;
  --toast2Warning: #dd7409;
  --toast2Success: #00854b;
  /* Lab 2.0 colors */
  --primaryLab: #1b1d1e;
  --primaryPanelLab: #131516;
  --primaryPanel2Lab: #2d2d2d;
  --textPrimary: #2a303c;
  --disableBtn: #96a1b5;
  --progressBar: #086ce8;
  --avatarBg: #cfe6fc;
  --checkIconBg: #b5d5fc;
  --textAreaBorder: rgba(42, 48, 60, 0.2);
  --crossIcon: rgba(42, 48, 60, 0.35);
  --errorBg: #fef3f1;
  --errorBorder: #fcded9;
  --sidePanelItemHoverBg: rgba(255, 255, 255, 0.35);
  --sidePanelDropdownBg: rgba(42, 48, 60, 0.1);
  --greenProgressBar: #00854b;
  --btnDanger: #d53811;
  --bellIcon: #f68c1f;
  --avatarBorder: #00a85f;
  --bgSecondary: #2d4965;
  --white: #ffffff;
  --bg3: #f6f7f9;
  --greyIcon: #2a303c59;
  --border4: #bebebe;
  --activeMenuItem: #282828;
  --activeMainMenuItem: #e7f3ff;
  --activeMainMenuItem2: rgba(8, 108, 232, 0.1);
  --elementHighlight: #29384a;
  --osChipActive: #0653b2;
  --breadcrumbGrey: #c3c9d5;
  --statusActiveBg: #f2fdf8;
  --statusInactive: #a52c0d;
  --osChipInfoBorder: #2b87f8;
  --labSecondary: #94784e;
  --serverEvent: #de0873;
  --billingEvent: #8308e8;
  --notificationEvent: #ffc41a;
  --userEvent: #03b1ba;
  --storageEvent: #d407c3;
  --networkevent: #53b7e8;
  /*TEXT COLOR*/
  --textDisabled: #96a1b5;
  /*BUTTON COLOR*/
  --buttonBlue: #086ce8;
  --buttonBlueHover: #2b87f8;
  --buttonBlueActive: #0653b2;
  --buttonBlueBorder: #b5d5fc;
  --buttonGreen: #008087;
  --buttonGreenHover: #4ca6ab;
  --buttonGreenActive: #004c51;
  --buttonGreenBorder: #b5d5fc;
  --buttonWhite: #ffffff;
  --buttonWhiteHover: #2b87f8;
  --buttonWhiteActive: #f0f7fe;
  --buttonDanger: #c95252;
  --buttonDangerHover: #ce7272;
  --buttonDangerActive: #ae4848;
  /*BADGE COLOR*/
  --badgeGrey: #131516;
  --badgeYellow: #131516;
  /*SHADOW COLOR*/
  --shadowLight: rgba(0, 0, 0, 0.3);
  --grayStatusDot: #546078;
  --redAsterisk: #d32f2f;
  --textYellow: #b56007;
  /*BORDER COLOR*/
  --borderYellow: #fabe80;
  --borderBlueish: #b5d5fc;

  /* Lab colors */
  --primaryBgLab: #2b3b4c;
  --secondaryBgLab: #3f485a;
  --accentBgLab: #1f2735;
  --highlightLab: #00d4df;
  --highlightBgLab: #e6f8f9;
  --highlghtAccessible: #008087;
  --primaryTextLab: #ffffff;
  --disabledTextLab: #96a1b5;
  --baseAccentLab: #00d4df;
  --borderBlue: #2b87f8;
  --primaryActive: #0653b2;
  --blue900: #021c3b;
  --translucentDark: #2a303c33;

  --green50: #f2fdf8;
  --green100: #baf7dd;
  --disabledFill: #f5f5f5;
  --blue50: #f0f7fe;
  --blue100: #cfe6fc;
  --translucentBlue: #086ce81a;
  --disabledPrimary: #707073;
}

#__next {
  @apply flex h-screen flex-col;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.circle-1 {
  animation-delay: 0.1s;
}
.circle-2 {
  animation-delay: 0.2s;
}
.circle-3 {
  animation-delay: 0.3s;
}

.numbered-list {
  list-style: none;
  counter-reset: item;
}
.numbered-list li {
  counter-increment: item;
  /* margin-bottom: 5px; */
}
.numbered-list li:before {
  margin-right: 10px;
  content: counter(item);
  background: #63718d;
  border-radius: 50%;
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  display: inline-block;
  flex-shrink: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* CUSTOM BUTTON CSS */
.btn-base {
  @apply rounded-md px-4 py-2 text-sm outline-none print:hidden;
}

.btn-link {
  @apply text-sm outline-none print:hidden;
}

.btn-solid-primary {
  background-color: var(--buttonBlue);
  color: var(--white);
  border: 1px solid transparent;
  transition: all ease 150ms;
}

.btn-solid-primary:hover {
  background-color: var(--buttonBlueHover);
}

.btn-solid-primary:active {
  background-color: var(--buttonBlueActive) !important;
}

.btn-solid-primary:focus {
  background: var(--buttonBlue);
}

.btn-solid-primary.disabled,
.btn-solid-primary:disabled {
  color: var(--white);
  background-color: var(--disableBtn) !important;
}

.btn-outline-secondary {
  background-color: var(--buttonWhite);
  color: var(--textPrimary);
  border: 1px solid var(--textPrimary);
  transition: all ease 150ms;
}

.btn-outline-secondary:hover {
  color: var(--buttonWhiteHover);
  border-color: var(--buttonWhiteHover);
}

.btn-outline-secondary:active {
  color: var(--buttonBlueActive) !important;
  border-color: var(--buttonBlueActive) !important;
  background-color: var(--buttonWhiteActive) !important;
}

.btn-outline-secondary:focus {
  background: var(--buttonWhite);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: var(--textPrimary) !important;
  background-color: var(--disableBtn) !important;
  border-color: var(--disableBtn) !important;
}

.btn-solid-secondary {
  background-color: var(--bgSecondary);
  color: var(--textPrimary);
  border: 1px solid var(--textPrimary);
  transition: all ease 150ms;
}

.btn-solid-secondary:hover {
  color: var(--buttonWhiteHover);
  border-color: var(--buttonWhiteHover);
}

.btn-solid-secondary:active {
  color: var(--buttonBlueActive) !important;
  border-color: var(--buttonBlue) !important;
  background-color: var(--buttonWhite) !important;
}

.btn-solid-secondary:focus {
  background: var(--bgSecondary);
}

.btn-solid-secondary.disabled,
.btn-solid-secondary:disabled {
  color: var(--textPrimary) !important;
  background-color: var(--disableBtn) !important;
  border-color: var(--disableBtn) !important;
}

.btn-outline-danger {
  background-color: var(--buttonWhite);
  color: var(--buttonDanger);
  border: 1px solid var(--buttonDanger);
  transition: all ease 150ms;
}

.btn-outline-danger:hover {
  color: var(--buttonDangerHover);
  border-color: var(--buttonDangerHover);
  background-color: var(--buttonDangerHoverBg);
}

.btn-outline-danger:active {
  color: var(--buttonDangerActive) !important;
  border-color: var(--buttonDangerActive) !important;
  background-color: var(--buttonDangerHoverBg) !important;
}

.btn-outline-danger:focus {
  background: var(--buttonWhite);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: var(--disableBtn) !important;
  background-color: transparent !important;
  border-color: var(--disableBtn) !important;
}

.btn-borderless {
  background-color: transparent;
  color: var(--textPrimary);
  border: 1px solid transparent;
  transition: all ease 150ms;
}

.btn-borderless:hover {
  color: var(--buttonBlueBorderlessHover);
  background-color: var(--buttonWhiteActive);
  border-color: var(--buttonBlueBorder);
}

.btn-borderless:active {
  color: var(--buttonBlueActive);
  background-color: var(--buttonBlueBorder) !important;
  border-color: var(--buttonBlueHover);
}

.btn-borderless.disabled,
.btn-borderless:disabled {
  color: var(--greyButton) !important;
  background-color: transparent !important;
  border-color: transparent;
}

.btn-danger {
  background-color: var(--buttonDanger);
  color: var(--white);
  border: 1px solid transparent;
  transition: all ease 150ms;
}

.btn-danger:hover {
  background-color: var(--buttonDangerHover);
}

.btn-danger:active {
  background-color: var(--buttonDangerActive) !important;
}

.btn-danger:focus {
  background: var(--buttonDanger);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: var(--textPrimary);
  background-color: var(--disableBtn) !important;
}

#truste-consent-track {
  width: 100%;
}

.date-picker > * {
  color: #686262;
}

.date-picker input {
  border: 1px solid #e7e9ee;
  height: 2.25rem;
  border-radius: 2px;
}

.date-picker input:focus {
  outline: none !important;
  border-color: #e7e9ee;
  box-shadow: none;
}

.date-picker div.absolute.z-20.h-4.w-4.rotate-45 {
  border-top: 1px solid #e7e9ee;
  border-left: 1px solid #e7e9ee;
  /* border-radius: 2px; */
}

.date-picker div.shadow-sm.border.border-gray-300 {
  border: 1px solid #e7e9ee;
  border-radius: 2px;
}

.date-picker div.transition-all.ease-out.duration-300.absolute z-10 {
  border: 0px;
  /* border-radius: 2px; */
}

.os-group-selector .btn-choose.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: var(--greyBorder) !important;
  border-color: var(--border4) !important;
  color: var(--textSecondary) !important;
}

.os-group-selector li > ul {
  transform: translatex(100%) scale(0);
}
.os-group-selector li:hover > ul {
  transform: translatex(101%) scale(1);
}
.os-group-selector li > button svg {
  transform: rotate(-90deg);
}

/* Hide TrustArc cookie preferences icon */
#teconsent {
  visibility: hidden;
}

nextjs-portal {
  display: none;
}

.mcn-step-header {
  width: 100%;
  height: 100px;
  background-image: url('/images/mcn/policy-drawer-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
